<template>
  <div class="summary-table">
    <loading-spinner v-if="isLoading" />
    <v-data-table
      v-else
      :headers="headers"
      :items="tableData"
      hide-default-footer
      mobile-breakpoint="960"
    >

    </v-data-table>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/GlobalComponents/LoadingSpinner'
import { mapGetters } from 'vuex'

export default {
  name: 'SummaryTable',
  components: {
    LoadingSpinner
  },
  data () {
    return {
      isLoading: false,
      interval: null,
      headers: [
        { text: this.$t('kpi.tables.users.summary.headers.kw'), value: 'kw' },
        { text: this.$t('kpi.tables.users.summary.headers.avg1'), value: 'avg1' },
        { text: this.$t('kpi.tables.users.summary.headers.avg2'), value: 'avg2' },
        { text: this.$t('kpi.tables.users.summary.headers.repairTime'), value: 'repairTime' },
        { text: this.$t('kpi.tables.users.summary.headers.workTime'), value: 'workTime' },
        { text: this.$t('kpi.tables.users.summary.headers.disponibleTime'), value: 'disponibleTime' },
        { text: this.$t('kpi.tables.users.summary.headers.helpCount'), value: 'helpCount' },
        { text: this.$t('kpi.tables.users.summary.headers.util'), value: 'util' }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapGetters({
      getWeek: 'KpiDateRangeFilter/getWeek',
      getUserData: 'User/getUserData'
    })
  },
  watch: {
    getWeek (newVal, oldVal) {
      this.getMachinesTableData()
    }
  },
  methods: {
    // TODO: zobrazit len vybraty tyzden (fixny pondelok - nedela)
  }
}
</script>

<style lang="scss" scoped>
div.summary-table {
  width: 100%;
  max-width: 1108px;
  background-color: $primary-white;
  padding: 32px;
}
</style>
