<template>
  <div class="kpi-users-wrapper">
    <div class="kpi-users-table">
      <div class="aligner">
        <users-table />
        <summary-table class="marginator" />
      </div>
    </div>
  </div>
</template>

<script>
import UsersTable from './UsersTable.vue'
import SummaryTable from './SummaryTable.vue'

export default {
  name: 'UsersKpi',
  components: {
    UsersTable,
    SummaryTable
  }
}
</script>

<style lang="scss" scoped>
div.kpi-users-wrapper {
  width: 100%;

  div.kpi-users-table {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 167px 167px 167px;

    div.aligner {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1108px;

      .marginator {
        margin-top: 32px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.kpi-users-wrapper {
    div.kpi-users.table {
      padding: 0;

      div.aligner {
        .marginator {
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
